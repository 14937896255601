export const routerList = [
  {
    path: "/",
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/pages/HomePage.vue"),
      },
      {
        path:'wingo',
        name:'wingo',
        component: () => import("@/pages/WingoPage.vue"),
      },
      {
        path:'k3',
        name:'k3',
        component: () => import("@/pages/K3Page.vue"),
      },
      {
        path:'k3Record',
        name:'k3Record',
        component: () => import("@/pages/K3RecordPage.vue"),
      },
      {
        path:'wingoRecord',
        name:'wingoRecord',
        component: () => import("@/pages/WingoRecordPage.vue"),
      }
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/LoginPage.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/pages/RegisterPage.vue"),
  },
  {
    path:'/reset',
    name:'reset',
    component: () => import("@/pages/ResetPage.vue"),
  },
  {
    path:'/confirm',
    name:'confirm',
    component: () => import("@/pages/ConfirmPage.vue"),
  },
  {
    path: "/activity",
    children: [
      {
        path: "",
        name: "activity",
        component: () => import("@/pages/ActivityPage.vue"),
      },
      {
        path: "attendance",
        name: "attendance",
        component: () => import("@/pages/AttendancePage.vue"),
      },
      {
        path: "message",
        name: "message",
        component: () => import("@/pages/MessagePage.vue"),
      },
      {
        path:'rules',
        name:'rules',
        component: () => import("@/pages/RulesPage.vue"),
      },
      {
        path:'inviteRule',
        name: 'inviteRule',
        component: () => import("@/pages/InviteRulePage.vue"),
      },
      {
        path:'inviteRecord',
        name: 'inviteRecord',
        component: () => import("@/pages/InviteRecordPage.vue"),
      }
    ],
  },
  {
    path: "/promotion",
    children: [
      {
        path: "",
        name: "promotion",
        component: () => import("@/pages/PromotionPage.vue"),
      },
      {
        path: "teamreport",
        name: "teamreport",
        component: () => import("@/pages/TeamreportPage.vue"),
      },
      {
        path: "commission",
        name: "commission",
        component: () => import("@/pages/CommissionPage.vue"),
      },
      {
        path: "promotionrule",
        name: "promotionrule",
        component: () => import("@/pages/PromotionRule.vue"),
      },
      {
        path:'promotionShare',
        name:'promotionShare',
        component: () => import("@/pages/PromotionSharePage.vue"),
      },
      {
        path: "rebateRatio",
        name: "rebateRatio",
        component: () => import("@/pages/RebateRatioPage.vue"),
      },
      {
        path:'Subordinate',
        name:'Subordinate',
        component: () => import("@/pages/SubordinatePage.vue"),
      }
    ],
  },
  {
    path: "/wallet",
    children: [
      {
        path: "",
        name: "wallet",
        component: () => import("@/pages/WalletPage.vue"),
      },
      {
        path: "recharge",
        name: "recharge",
        component: () => import("@/pages/RechargePage.vue"),
      },
      {
        path: "rechargeHistory",
        name: "rechargeHistory",
        component: () => import("@/pages/RechargeHistoryPage.vue"),
      },
      {
        path: "withdraw",
        name: "withdraw",
        component: () => import("@/pages/WithdrawPage.vue"),
      },
      {
        path: "withdrawHistory",
        name: "withdrawHistory",
        component: () => import("@/pages/WithdrawHistoryPage.vue"),
      },
      {
        path: "transaction",
        name: "transaction",
        component: () => import("@/pages/TransactionPage.vue"),
      },
    ],
  },
  {
    path: "/account",
    children: [
      {
        path: "",
        name: "account",
        component: () => import("@/pages/AccountPage.vue"),
      },
      {
        path: "language",
        name: "language",
        component: () => import("@/pages/LanguagePage.vue"),
      },
      {
        path: "game",
        name: "game",
        component: () => import("@/pages/GamePage.vue"),
      },
      {
        path: "gift",
        name: "gift",
        component: () => import("@/pages/GiftPage.vue"),
      },
      {
        path: "service",
        name: "service",
        component: () => import("@/pages/ServicePage.vue"),
      },
      {
        path: "setting",
        name: "setting",
        component: () => import("@/pages/SettingPage.vue"),
      },
      {
        path: "feedback",
        name: "feedback",
        component: () => import("@/pages/FeedbackPage.vue"),
      },
      {
        path: "notification",
        name: "notification",
        component: () => import("@/pages/NotificationPage.vue"),
      },
      {
        path: "about",
        name: "about",
        component: () => import("@/pages/AboutPage.vue"),
      },
      {
        path: "guide",
        name: "guide",
        component: () => import("@/pages/GuidePage.vue"),
      },
      {
        path: "betrecord",
        name: "betrecord",
        component: () => import("@/pages/BetrecordPage.vue"),
      },
      {
        path: "laundry",
        name: "laundry",
        component: () => import("@/pages/LaundryPage.vue"),
      },
      {
        path: "invitation",
        name: "invitation",
        component: () => import("@/pages/InvitationPage.vue"),
      },
      {
        path: "loginPassword",
        name: "loginPassword",
        component: () => import("@/pages/LoginPassPage.vue"),
      },
      {
        path: "bindEmail",
        name: "bindEmail",
        component: () => import("@/pages/BindEmailPage.vue"),
      },
      {
        path: "avatar",
        name: "avatar",
        component: () => import("@/pages/AvatarPage.vue"),
      },
    ],
  },
  {
    path: "/vip",
    children:[
      {
        path:'',
        name: "vip",
        component: () => import("@/pages/VipPage.vue"),
      },
      {
        path:'experience',
        name: "experience",
        component: () => import("@/pages/ExperiencePage.vue"),
      }
    ]
    
  },
];
